<!-- eslint-disable vue/require-v-for-key -->
<template>
<div>
  <div class="row text-right">
    <div class="col-md-12">
      <button type="button" class="btn btn-primary btn-xs" @click="addModal"><i class="fas fa-plus"></i> Neu</button>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
        <div class="timeline">
          <template v-for="(entries,index) in timeline">
              <div class="time-label">
                <span class="bg-green">{{ index | formatDate }}</span>
              </div>
              <template v-for="entry in entries">
                <div v-if="entry.event == 'message'" :key="entry.id">
                  <i class="fas fa-envelope bg-blue"></i>
                  <div class="timeline-item" >
                    <span class="time">
                      <i v-if="entry.causer_id == $auth.user().id" class="fas mr-1 fa-edit" @click="editModal(entry)"></i>
                      <i v-if="entry.causer_id == $auth.user().id" class="fas mr-1 fa-trash" @click="deleteMessage(entry.id)"></i>
                      <i class="fas fa-clock"></i>
                      {{ entry.created_at | formatTime }}
                    </span>
                    <h3 class="timeline-header">
                      {{ entry.properties.title }}
                    </h3>
                    <div class="timeline-body" v-html="entry.properties.message"></div>
                    <div class="timeline-footer">
                      <small>
                        <span v-if="entry.causer != null">Erstellt von: {{ entry.causer.first_name }} {{ entry.causer.last_name }}</span>
                        <span v-else>Erstellt von: System</span>
                      </small>
                    </div>
                  </div>
                </div>

                <div v-if="entry.event == 'dealer_order'" :key="entry.id">
                  <i class="fas fa-clipboard bg-secondary"></i>
                  <div class="timeline-item" >
                    <span class="time">
                      <i class="fas fa-clock"></i>
                      {{ entry.created_at | formatTime }}
                    </span>
                    <h3 class="timeline-header">
                      Händlerbestellung: {{ entry.properties.ordernumber }}
                    </h3>
                    <div class="timeline-body">
                      <router-link :to="{name: 'dealer-orders.details', params:{ id: entry.properties.order_id}}" class="mt-2 btn btn-xs btn-secondary">Zur Bestellung</router-link>
                    </div>
                    <div class="timeline-footer">
                      <small>
                        <span v-if="entry.causer != null">Erstellt von: {{ entry.causer.first_name }} {{ entry.causer.last_name }}</span>
                        <span v-else>Erstellt von: System</span>
                      </small>
                    </div>
                  </div>
                </div>

                <div v-if="entry.event == 'order_images'" :key="entry.id">
                  <i class="fas fa-clipboard bg-secondary"></i>
                  <div class="timeline-item" >
                    <span class="time">
                      <i class="fas fa-clock"></i>
                      {{ entry.created_at | formatTime }}
                    </span>
                    <h3 class="timeline-header">
                      Händlerbestellung: {{ entry.properties.ordernumber }}
                    </h3>
                    <div class="timeline-body">
                      <router-link :to="{name: 'dealer-orders.details', params:{ id: entry.properties.order_id}}" class="mt-2 btn btn-xs btn-secondary">Zur Bestellung</router-link>
                      <div class="row">
                        <div class="col-md-2" v-for="(photo, photoIndex) in entry.properties.photos" :key="photoIndex">
                          <img style="width: 70%; height: auto" :src="photo" class="p-1">
                        </div>
                      </div>
                    </div>
                    <div class="timeline-footer">
                      <small>
                        <span v-if="entry.causer != null">Erstellt von: {{ entry.causer.first_name }} {{ entry.causer.last_name }}</span>
                        <span v-else>Erstellt von: System</span>
                      </small>
                    </div>
                  </div>
                </div>

                <div v-if="entry.event == 'dealer_order_processed'" :key="entry.id">
                  <i class="fas fa-clipboard bg-secondary"></i>
                  <div class="timeline-item" >
                    
                    <span class="time">
                      <i class="fas fa-clock"></i>
                      {{ entry.created_at | formatTime }}
                    </span>
                    <h3 class="timeline-header">
                      Händlerbestellung: {{ entry.properties.ordernumber }} wurde abgerechnet / ausgeliefert!
                    </h3>
                    <div class="timeline-body">
                      <router-link :to="{name: 'dealer-orders.details', params:{ id: entry.properties.order_id}}" class="mt-2 btn btn-xs btn-secondary">Zur Bestellung</router-link>
                    </div>
                    <div class="timeline-footer">
                      <small>
                        <span v-if="entry.causer != null">Erstellt von: {{ entry.causer.first_name }} {{ entry.causer.last_name }}</span>
                        <span v-else>Erstellt von: System</span>
                      </small>
                    </div>
                  </div>
                </div>

                <div v-if="entry.event == 'articles_attached' || entry.event == 'articles_detached'" :key="entry.id">
                  <i class="fas fa-clipboard bg-secondary"></i>
                  <div class="timeline-item" >
                    
                    <span class="time">
                      <i class="fas fa-clock"></i>
                      {{ entry.created_at | formatTime }}
                    </span>
                    <h3 class="timeline-header">
                      <span v-if="entry.event == 'articles_attached'">Artikel wurden hinzugefügt:</span>
                      <span v-if="entry.event == 'articles_detached'">Artikel wurden entfernt:</span>
                    </h3>
                    <div class="timeline-body">
                      <div id="accordion">
                        <div class="card">
                          <div class="card-header" id="headingOne">
                            <h5 class="mb-0">
                              <button class="btn btn-primary" data-toggle="collapse" :data-target="'#collapse'+entry.id" aria-expanded="true" :aria-controls="'collapse'+entry.id">
                                Zeige Artikel
                              </button>
                            </h5>
                          </div>

                          <div :id="'collapse'+entry.id" class="collapse" :aria-labelledby="'heading'+entry.id" data-parent="#accordion">
                            <div class="card-body">
                              <ul>
                                <li v-for="article in entry.properties.articles" :key="article.id">  
                                  <router-link :to="{'name': 'articles.details', 'params': {'id': article.article_id}}" >{{ article.ordernumber }} - {{ article.name }}</router-link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      
                    </div>
                    <div class="timeline-footer">
                      <small>
                        <span v-if="entry.causer != null">Erstellt von: {{ entry.causer.first_name }} {{ entry.causer.last_name }}</span>
                        <span v-else>Erstellt von: System</span>
                      </small>
                    </div>
                  </div>
                </div>
              </template>
              
          </template>

          <div class="mt-3">
            <i class="fas fa-clock bg-gray"></i>
            <div class="timeline-item" v-if="timeline.length == 0">
              <h3 class="timeline-header">
                Keine Einträge vorhanden
              </h3>
            </div>
          </div>
        </div>
    </div>
  </div>

  <b-modal id="messageModal" :title="modalTitle" ok-only ok-variant="danger" ok-title="Speichern" size="lg" @ok="handleOk">
    <div class="form-group">
      <label for="title" class="control-label">Titel*</label>
      <input type="text" v-model="form.title" class="form-control form-control-sm" />
      <has-error :form="form" field="title"></has-error>
    </div>

    <div class="form-group">
        <label for="description_long" class="control-label">Nachricht</label>
        <ckeditor :editor="editor" :config="editorConfig" v-model="form.message" :class="{'is-invalid': form.errors.has('message')}" v-if="notEmptyObject(form)"></ckeditor>
        <has-error :form="form" field="message"></has-error>
    </div>

  </b-modal>
</div>
</template>

<script>
import ClassicEditor from './../../../assets/packages/ckeditor5/src/ckeditor';

export default {
  props: [
    'dealer'
  ],

  data() {
        return {
            form: new window.Form({
                id: "",
                title: "",
                message: "",
            }),
            timeline: [],
            editor: ClassicEditor,
            editorConfig: {
                simpleUpload: {
                    uploadUrl: process.env.VUE_APP_API_URL + 'images/upload',
                    withCredentials: true,
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer '+ this.$auth.token()
                    }
                }
            },
            editMode: false,
            modalTitle: "",
        }
  },

  methods:{
    getActivities(){
        this.axios
            .get("/dealers/" + this.dealer.id + '/activities')
            .then((response) => {
                this.timeline = response.data;
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufen",
                });
            });
    },

    editModal(message){
      this.editMode = true;
      this.modalTitle = "Nachricht editieren";
      this.form.clear();
      this.form.reset();
      this.form.id = message.id;
      this.form.title = message.properties.title;
      this.form.message = message.properties.message;
      this.$bvModal.show("messageModal");
    },

    addModal(){
      this.editMode = false;
      this.modalTitle = "Nachricht anlegen";
      this.form.clear();
      this.form.reset();
      this.$bvModal.show("messageModal");
      this.form.id = this.dealer.id;
    },

    deleteMessage(id){
      this.$swal({
                title: "Möchtest du den Eintrag wirklich löschen?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `Ja, löschen!!!`,
                denyButtonText: `Abbrechen`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    //Send request to the Server
                    this.axios
                        .delete("/dealers/timeline/delete/message", {
                          params: {
                            id
                          }
                        })
                        .then(() => {
                            this.$swal({
                                icon: "success",
                                title: "Eintrag erfolgreich gelöscht!",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                                });
                            this.getActivities();
                        })
                        .catch(() => {
                            this.$swal({
                                icon: "error",
                                title: "Oops...",
                                text: "Da ist etwas schief gelaufen",
                            });
                        });
                }
            });
    },
    
    createMessage(){
      this.form
            .post("/dealers/timeline/add/message")
            .then(() => {
                this.$swal({
                    icon: "success",
                    title: "Eintrag wurde gespeichert",
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                });
                this.getActivities();
                this.$bvModal.hide("messageModal");
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufen",
                });
            });
    },

    editMessage(){
      this.form
            .put("/dealers/timeline/update/message")
            .then(() => {
                this.$swal({
                    icon: "success",
                    title: "Eintrag wurde gespeichert",
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                });
                this.getActivities();
                this.$bvModal.hide("messageModal");
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufen",
                });
            });
    }, 

    handleOk(bvModalEvt){
        bvModalEvt.preventDefault();
        //Update die Mappings
        if(this.editMode == true)
        {
            //Edit User
            this.editMessage(); 
        }
        else
        {
            //Create User
            this.createMessage();
        }  
    }
  },

  mounted(){
    this.getActivities();
  }
}
</script>

<style>

</style>